<template>
  <div v-clipboard:copy="text" v-clipboard:success="onCopy" v-clipboard:error="onError">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: require('@/assets/images/profile/success.svg')
    }
  },
  methods: {
    onCopy: function (e) {
      this.$toast({
        message: this.$t('profile.copy'),
        icon: this.icon
      })
    },
    onError: function (e) {
      this.$toast({
        message: this.$t('profile.copy'),
        icon: 'close'
      })
    }
  }
}
</script>
