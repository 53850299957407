<template>
  <div class="profile-about-wrapper">
    <div class="profile-about-wrapper-logo"></div>
    <div class="profile-about-wrapper-item">
      <div class="left-content">
        {{ $t('auth.email') }}
      </div>
      <div class="right-content ub ub-cen text">
        <span>{{ email }}</span>
        <CopyWrap :text="email">
          <img src="@/assets/images/profile/copy.svg"/>
        </CopyWrap>
      </div>
    </div>
    <div class="profile-about-wrapper-item">
      <div class="left-content">
        {{ $t('profile.servicesAgreement') }}
      </div>
      <div class="right-content ub ub-cen">
        <img src="@/assets/images/profile/rightArrow.svg"/>
      </div>
    </div>
    <div class="origin">
      {{ host }}
    </div>
    <!-- <div class="profile-about-wrapper-item">
      <div class="left-content">
        {{ $t('profile.checkUpdate') }}
      </div>
      <div class="right-content ub ub-cen">
        <span class="dot"></span>
        <img src="@/assets/images/profile/rightArrow.svg"/>
      </div>
    </div>
    <div class="profile-about-wrapper-item">
      <div class="left-content =">
        {{ $t('profile.clearCache') }}
      </div>
      <div class="right-content ub ub-cen text">
        <span>12.34 MB</span>
        <img src="@/assets/images/profile/rightArrow.svg"/>
      </div>
    </div> -->
  </div>
</template>
<script>
import CopyWrap from '@/components/copy/index.vue'

export default {
  components: { CopyWrap },
  computed: {
    email () {
      return this.$store.state.userInfo?.email
    },
    host () {
      return process.env.VUE_APP_BASE_API
    }
  }
}
</script>
<style lang="less">
.profile-about-wrapper {
  padding: 0 30px;
  .dot {
    width: 12px;
    height: 12px;
    background-color: var(--error-color);
    border-radius: 50%;
  }
  .text {
    color: var(--main-text-3);
    text-align: right;
    font-family: Roboto;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
  }
  .origin {
    position: fixed;
    bottom: 240px;
    left: 50%;
    transform: translateX(-50%);
    color: var(--primary-color);
    font-family: Roboto;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
  }
  &-item {
    display: flex;
    width: 100%;
    height: 96px;
    justify-content: space-between;
    align-items: center;
    img {
      width: 48px;
      height: 48px;
    }
    .avatar {
      border-radius: 60px;
    }
    .left-content {
      color: var(--main-text-1);
      font-family: Roboto;
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: 48px; /* 150% */
    }
    .right-content {
      line-height: 0;
    }
  }
}
</style>
